.cal-header {
    .cal-cell {
        color: $light
    }
}
.cal-day-badge {
    background-color: $red !important;
}
.cal-month-view .cal-open-day-events {
    background-color: $gray-200;
    box-shadow: none;
    color: $foreground;
}
.cal-event-action i {
    vertical-align: middle;
}
.cal-month-view .cal-day-cell.cal-today {
    background: rgba($primary, .2)
}