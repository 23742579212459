.icon-regular {
    font-size: 18px;
    -webkit-font-smoothing: subpixel-antialiased;
}
.link-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $foreground;
    i {
        margin: 0 8px;
    }
}